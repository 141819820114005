<!-- BCT: Added by BCT -->
<!-- BCT: Added by BCT -->
<!-- BCT: Added by BCT -->
<template>
  <div>
    <div v-if="featureListLoading && !featureListLoaded">
      <div class="text-center px-10 py-10">
        <v-progress-circular
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <div v-if="!featureListLoading && featureListLoaded">
      <div v-if="addedFeaturesList.length">
        <hb-data-table-header>
          <template v-slot:description>
            Arrange the order of features and amenities by importance or desirability. Drag and drop <hb-icon class="vertical-align-middle" style="position:relative;top:3px;">mdi-drag-custom</hb-icon> each row.
          </template>
          <template v-slot:actions>
            <hb-btn color="secondary" small @click="onClickAddFeaturesAndAmenities">Add Features and Amenities</hb-btn>
          </template>
        </hb-data-table-header>
        <hb-data-table
          :headers="headers"
          :items="addedFeaturesList"
        >
          <template v-slot:body="props">
            <draggable
              :list="props.items"
              tag="tbody"
              @end="saveSortOrder(props.items)"
            >
              <tr v-for="(item, index) in props.items" :key="index" @click="editItem(item)">
                <td>
                  <hb-btn icon tooltip="Drag" active-state-off>
                    mdi-drag-custom
                  </hb-btn>
                </td>
                <td class="capitalize">{{ item.feature }}</td>
                <td class="capitalize">{{ item.category_name }}</td>
                <td>
                  <span v-if="item.options.type === 'checkbox'">Yes / No</span>
                  <hb-tooltip
                    v-else-if="item.options.type === 'select'"
                    dashed

                  >
                    <template v-slot:item>
                      {{ item.options.options.length }}
                      {{
                        item.options.options.length > 1
                          ? "Values"
                          : "Value"
                      }}
                    </template>
                    <template v-slot:body>
                      <div
                        v-for="(option, i) in item.options.options"
                        :key="i"
                      >
                        {{ option }}
                      </div>
                      <div v-if="item.options.options.length > 15">...</div
                      >
                    </template>
                  </hb-tooltip>
                  <span class="text-capitalize" v-else>{{
                    item.options.type
                  }}</span>
                </td>
                <td>{{ item.default }}</td>
                <td>
                  <hb-menu
                    options
                    align-right
                  >
                    <v-list>
                      <v-list-item @click="editItem(item)">
                        <v-list-item-title>View / Edit</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="onClickDeleteAmenity(item)">
                        <v-list-item-title>Delete</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </hb-menu>
                </td>
              </tr>
            </draggable>
          </template>
        </hb-data-table>
      </div>
      <hb-empty-state
        v-else 
        :message="'Add the features and amenities you want to make available for '+activeTab.title"
        btn-txt="Add Features and Amenities"
        btnColor="primary"
        @clicked="onClickAddFeaturesAndAmenities"
      >
      </hb-empty-state>
    </div>

    <!--// Delete Features and Amenities Popup -->
    <delete-feaure-and-amenity
      v-if="showDeleteDialog"
      :property_id="property_id"
      :activeTab="activeTab"
      :amenityToDelete="amenityToDelete"
      v-model="showDeleteDialog"
      @close="showDeleteDialog = false"
    >
    </delete-feaure-and-amenity>
    <!-- Delete Features and Amenities Popup //-->

    <edit-feature-and-amenity
      v-if="editAmenity"
      :property_id="property_id"
      :amenity_id="selectedAmenity.amenity_id"
      :activeTab="activeTab"
      v-model="editAmenity"
      @close="editAmenity = false"
    ></edit-feature-and-amenity>

    <!-- // Restricted Permission warning popup -->
    <hb-modal
      v-model="showNoPermissionDialog"
      size="medium"
      :title="'Unable to ' + permissionDialogtitle"
      :footerOff="true"
      confirmation
      @close="showNoPermissionDialog = false"
      show-help-link
    >
      <template v-slot:content>
        <div class="px-6 py-4">
          <div class="pb-2">The signed in account does not have permission to <span class="text-lower-case">{{permissionDialogtitle}}</span>.</div>
          <div>To <span class="text-lower-case">{{permissionDialogtitle}}</span>, contact your administrator to enable permission.</div>
        </div>
      </template>
    </hb-modal>
    <!-- Restricted Permission warning popup // -->

  </div>
</template>

<script type="text/babel">
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "../../../EventBus.js";
import Draggable from "vuedraggable";
import DeleteFeaureAndAmenity from './DeleteFeatureAndAmenity.vue';
import EditFeatureAndAmenity from "./EditFeatureAndAmenity.vue";

export default {
  name: "FeaturesAndAmenitiesList",
  data() {
    return {
      headers: [
        { text: "", value: "drag", align: "left", sortable: false, width: 10 },
        { text: "Feature / Amenity", value: "feature", sortable: false },
        {
          text: "Feature and Amenity Category",
          value: "category_name",
          sortable: false
        },
        { text: "Options", value: "options", sortable: false },
        { text: "Default", value: "default", sortable: false },
        {
          text: "",
          value: "actions",
          align: "right",
          sortable: false,
          width: 10
        }
      ],
      amenityToDelete: {},
      showDeleteDialog: false,
      selectedAmenity: {},
      editAmenity: false,
      showNoPermissionDialog: false,
      permissionDialogtitle: ''
    };
  },
  props: {
    activeTab: {
      type: Object
    },
    property_id: {
      type: String
    }
  },
  components: {
    Draggable,
    DeleteFeaureAndAmenity,
    EditFeatureAndAmenity
  },
  computed: {
    ...mapGetters({
      addedFeaturesList: "featureAndAmenitiesStore/addedFeaturesList",
      featureListLoading: "featureAndAmenitiesStore/addedFeaturesListLoading",
      featureListLoaded: "featureAndAmenitiesStore/addedFeaturesListLoaded",
      hasPermission: 'authenticationStore/rolePermission',
    })
  },
  methods: {
    ...mapActions({
      saveFeatures: "featureAndAmenitiesStore/saveFeatures",
    }),
    saveSortOrder(amenities) {
      this.saveFeatures({ data: JSON.parse(JSON.stringify(amenities)), sort: true })
        .then(r => EventBus.$emit('unit_edited'))
    },
    editItem(item) {
      if (this.hasPermission('edit_features_amenities')) {
        this.editAmenity = true;
        this.selectedAmenity = item;
      } else {
        this.permissionDialogtitle = 'Edit Features and Amenities';
        this.showNoPermissionDialog = true;
      }
    },
    onClickDeleteAmenity(amenity) {
      if (this.hasPermission('delete_features_amenities')) {
        this.amenityToDelete = amenity;
        this.showDeleteDialog = true;
      } else {
        this.permissionDialogtitle = 'Delete Feature and Amenity';
        this.showNoPermissionDialog = true;
      }
    },
    onClickAddFeaturesAndAmenities() {
      if (this.hasPermission('add_features_amenities')) {
        this.$emit("addFeaturesAndAmenitiesEvent");
      } else {
        this.permissionDialogtitle = 'Add Features and Amenities';
        this.showNoPermissionDialog = true;
      }
    }
  }
};
</script>

<style scoped>
.v-list-item {
  min-height: 34px;
}
.v-list-item__title {
  min-width: 110px;
}
.align-items-center {
  align-items: center;
}
.vertical-align-middle {
  vertical-align: middle;
}
.border-bottom-dashed {
  border-bottom: 1px dashed;
}
.capitalize {
  text-transform: capitalize;
}

.text-lower-case {
  text-transform: lowercase;
}

.list-item-height {
  min-height: 40px;
}
</style>
