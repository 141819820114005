<!-- BCT: Added by BCT -->
<!-- BCT: Added by BCT -->
<!-- BCT: Added by BCT -->
<template>
  <div>
    <!--// Delete Feature and Amenity Confirmation Popup -->
    <hb-modal
      confirmation
      v-if="showConfirmDialog"
      v-model="showConfirmDialog"
      size="medium"
      title="Confirm"
      :footerCancelOption="false"
      show-help-link
      @close="closeDialog"
    >
      <template v-slot:content>
        <div class="pa-5">
          Deleting this feature or amenity will remove it, and its assigned values, from all the spaces it's associated with at this property.
        </div>
        <div class="pa-5 pt-0">
          If you add this feature or amenity again, you'll need to reassign it to your desired spaces and set new values for it. Would you like to proceed?
        </div>
      </template>
      <template v-slot:right-actions>
        <a class="hb-link ml-1" @click="closeDialog">
          Cancel
        </a>
        <hb-btn @click="onClickDeleteAmenityConfirm" color="primary" :disabled="isLoading('delete_amenity')" :loading="isLoading('delete_amenity')">
          Delete
        </hb-btn>
      </template>
    </hb-modal>
    <!-- Delete Feature and Amenity Confirmation Popup //-->
  </div>
</template>

<script>
import { mapActions,mapGetters } from "vuex";
import { notificationMixin } from  '@/mixins/notificationMixin.js';

export default {
  name: "DeleteFeaureAndAmenity",
  props: {
    amenityToDelete: {
      type: Object
    },
    property_id: {
      type: String
    },
    activeTab: {
      type: Object
    }
  },
  mixins: [notificationMixin],
  data() {
    return {
      showConfirmDialog: false
    };
  },
  created() {
    this.showConfirmDialog = true;
  },
  computed: {
    ...mapGetters({
      addedFeaturesList: "featureAndAmenitiesStore/addedFeaturesList"
    }),
    getModifiedAmenitiesList() {
      return this.addedFeaturesList.map((amenity) => {
        if (amenity.amenity_id === this.amenityToDelete.amenity_id) {
          return { ...amenity, checked: false }
        }
        return amenity
      })
    }
  },
  methods: {
    ...mapActions({
      fetchFeatures: "featureAndAmenitiesStore/fetchFeatures",
      saveFeatures: "featureAndAmenitiesStore/saveFeatures"
    }),

    onClickDeleteAmenityConfirm() {
      this.startLoading('delete_amenity');
      const amenities = this.getModifiedAmenitiesList;
      this.saveFeatures({ data: JSON.parse(JSON.stringify(amenities)), isDelete: true, spaceType: this.activeTab.unit_type_name,unit_type_id:this.activeTab.unit_type_id })
        .then(r => {
          this.stopLoading('delete_amenity');
          this.closeDialog();
        })
      .catch((err) => {
        this.showMessageNotification({description: ' ', note: err});
        this.stopLoading('delete_amenity');
        this.closeDialog();
      });
    },

    closeDialog() {
      this.$emit("close");
    },
  }
};
</script>

<style></style>
